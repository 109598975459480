import React, { useMemo, useRef, useState } from "react";
import { useField } from "formik";
import classnames from "classnames";

const FormikField = ({ type, name, label, required, ...rest }) => {
  const input = useRef();
  const [focus, setFocus] = useState(false);

  const [{ onBlur, ...field }, meta] = useField(name);

  const error = useMemo(
    () => (meta.touched && !!meta.error ? meta.error : ""),
    [meta.error, meta.touched]
  );

  const handleClick = () => {
    input.current.select();
    input.current.focus();
  };

  return (
    <div className="w-full px-2 my-1.5">
      <div
        onClick={handleClick}
        className={classnames(
          "relative group px-4 py-1.5 border-2 bg-white w-full transition duration-200",
          { "border-red-400": error, "border-primary": !error }
        )}
      >
        <label
          onClick={handleClick}
          className={classnames(
            "absolute px-1 mx-2 text-md top-2 bg-white group-hover:text-primary transform transition duration-200 left-2 text-opacity-40 cursor-text",
            { "-translate-y-6 -translate-x-2": focus || field.value }
          )}
        >
          {label}
          {required ? <em className="text-red-600">*</em> : null}
        </label>
        <input
          onFocus={() => setFocus(true)}
          onBlur={(event) => {
            setFocus(false);
            onBlur(event);
          }}
          ref={input}
          {...field}
          className="w-full bg-white text-lg"
          type={type}
          {...rest}
        />
      </div>
      <div
        className={classnames("text-danger text-right px-2 text-xs", {
          invisible: !error,
        })}
      >
        {error || "none"}
      </div>
    </div>
  );
};

export default FormikField;
