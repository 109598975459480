import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import access from "../assets/carousel/box-accessible-24h-24.svg";
import clean from "../assets/carousel/locaux-de-stockage-propres.svg";
import security from "../assets/carousel/securite-centre-stockage.svg";
import service from "../assets/carousel/service-client-montpellier.svg";

const Carrousel = () => {
  const options = {
    draggable: false,
    arrows: false,
    dots: true,
    slidesToShow: 3,
    centerMode: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    centerPadding: "10px",
    center: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const content = [
    {
      title: "Le meilleur service client",
      text:
        "Pour vous assurer de récupérer vos affaires en parfait\n" +
        "état, même après les avoir stockées plusieurs années,\n" +
        "nous choisissons uniquement des garde-meuble\n" +
        "sains. Chaque espace de stockage est à l’abri de\n" +
        "l’humidité et permet à l’air de circuler.",
      img: service,
    },
    {
      title: "Accès illimité 7J/7",
      text:
        "Vous pourrez accéder à vos affaires tous les jours de\n" +
        "l’année, sans exception dans nos garde-meuble\n" +
        "partenaires.",
      img: access,
    },
    {
      title: "Sécurité",
      text:
        "Les garde-meuble proposés disposent de caméras\n" +
        "de surveillance 24 heures sur 24",
      img: security,
    },
    {
      title: "Locaux propres et secs",
      text:
        "Pour vous assurer de récupérer vos affaires en parfait\n" +
        "état, même après les avoir stockées plusieurs années,\n" +
        "nous choisissons uniquement des garde-meuble\n" +
        "sains. Chaque espace de stockage est à l’abri de\n" +
        "l’humidité et permet à l’air de circuler.",
      img: clean,
    },
  ];

  return (
    <Slider {...options}>
      {content.map((item, index) => (
        <div key={index}>
          <div
            style={{ height: "400px" }}
            className="w-64 mx-auto bg-primary rounded-2xl py-5 px-5"
          >
            <div className="mx-auto flex flex-col justify-around h-full">
              <img className="mx-auto h-32" src={item.img} alt={item.title} />
              <div className="text-center text-white">
                <h4 className="w-full text-xl font-bold mt-2">{item.title}</h4>
                <p className="text-sm">{item.text}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default Carrousel;
