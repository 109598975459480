import logo from "./../../assets/logo1.png";

const Header = () => {
  return (
    <header id="top" className="mb-8">
      <div className="flex justify-around bg-primary w-full">
        <div className="w-full h-full">
          <a href="/">
            <img
              src={logo}
              width={250}
              alt="logo"
              className="mx-auto px-1 my-2"
            />
          </a>
        </div>
        <div className="w-full flex justify-center h-full my-auto">
          <a
            href="#formulaire"
            className="text-primary bg-white rounded-full py-1 px-10 shadow-md cursor-pointer font-bold hover:shadow-xl transition-shadow duration-200"
          >
            Obtenir les tarifs
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
