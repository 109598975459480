import { Layout } from "./components/layout";
import Formulaire from "./components/Formulaire";
import Carrousel from "./components/Carrousel";
import ScrollTop from "./components/common/ScrollTop";

import devis from "./assets/devis-sans engagement-a-nimes.png";
import gratuit from "./assets/comparateur-gratuit-box-stockage.png";
import main from "./assets/main.png";

import step1 from "./assets/step1.png";
import step2 from "./assets/step2.png";
import step3 from "./assets/step3.png";

import check from "./assets/check.png";
import mtp1 from "./assets/mtp1.png";
import mtp2 from "./assets/mtp2.png";

const App = () => {
  return (
    <Layout>
      <div className="w-full m-auto" style={{ maxWidth: "1200px" }}>
        <div className="xl:px-16 md:px-10 px-2">
          <div className="flex justify-around flex-wrap-reverse w-full">
            <div className="my-auto">
              <div
                style={{ backgroundImage: `url('${main}')` }}
                className="px-64 py-72 rounded-lg bg-right bg-cover bg-no-repeat shadow-xl"
              />
            </div>

            <div
              id="formulaire"
              className="w-full px-4"
              style={{ maxWidth: "450px" }}
            >
              <h1 className="text-3xl text-center text-primary font-semibold tracking-wider">
                Trouver votre{" "}
                <span className="text-secondary"> garde-meuble </span> sur
                Montpellier
              </h1>

              <Formulaire />

              <div className="flex justify-around my-4 w-full">
                <div className="flex justify-between">
                  <img
                    src={devis}
                    style={{ width: 47, height: 40 }}
                    alt="devis"
                  />
                  <p className="mx-4 text-primary text-xs">
                    Devis 100% <span className="font-bold">gratuit</span> et{" "}
                    <span className="font-bold">sans engagement</span>
                  </p>
                </div>
                <div className="flex justify-between">
                  <img src={gratuit} width={50} alt="gratuit" />
                  <p className="mx-4 text-primary text-xs">
                    Sélection des{" "}
                    <span className="font-bold">
                      meilleurs garde-meuble à Montpellier
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="fonctionnement" className="mx-auto w-full">
          <div className="text-center mt-28 w-11/12 laptop:w-3/4 mx-auto">
            <h2 className="text-2xl laptop:text-3xl font-semibold">
              Louez un garde-meuble à Montpellier en{" "}
              <span className="text-primary"> 3 étapes </span>
            </h2>
          </div>
          <div className="py-10 w-11/12 mx-auto flex justify-center">
            <div>
              <img
                src={step3}
                className="mx-auto"
                width={250}
                alt="conseillers"
                loading="lazy"
              />
              <p className="text-center mt-2 w-10/12 mx-auto">
                Nos conseillers reçoivent votre demande et vous proposent les
                meilleurs{" "}
                <span className="font-bold"> garde-meubles à Montpellier </span>
              </p>
            </div>
            <div>
              <img
                src={step1}
                className="mx-auto"
                width={250}
                alt="conseillers"
                loading="lazy"
              />
              <p className="text-center mt-2 w-10/12 mx-auto">
                Vous choisissez le gardemeubles qui vous convient le mieux et
                validez votre <span className="font-bold"> date d’entrée </span>
              </p>
            </div>
            <div>
              <img
                src={step2}
                className="mx-auto"
                width={250}
                alt="conseillers"
                loading="lazy"
              />
              <p className="text-center mt-2 w-10/12 mx-auto">
                Tout est prêt, il ne vous reste plus qu’à commencer à{" "}
                <span className="font-bold">
                  stocker dans votre garde-meuble !
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="py-5" style={{ backgroundColor: "rgb(238, 238, 238)" }}>
          <div className="mx-auto">
            <h2
              id="trouver"
              className="text-center text-3xl font-bold w-full mx-auto"
            >
              Nous comparons pour vous les meilleurs <br />
              <span className="text-primary">garde-meubles de Montpellier</span>
            </h2>
          </div>
          <div className="bg-white bg-opacity-90 shadow-lg rounded-3xl w-11/12 laptop:w-3/4 laptop:right-12 mx-auto px-2 sm:px-8 py-2 my-10 laptop:min-w-950">
            <div className="my-4">
              <h3 className="text-primary font-semibold text-2xl text-center my-1">
                Trouver un garde-meuble pas cher à Montpellier
              </h3>
              <p className="text-sm laptop:text-md leading-5 w-10/12 mx-auto text-center text-lg">
                Nous comparons les{" "}
                <a
                  className="text-primary font-semibold hover:underline visited:text-black"
                  href="https://www.atout-box.fr/"
                >
                  garde-meuble de Montpellier{" "}
                </a>
                pour vous. Vous recevrez un comparatif des garde-meuble les
                moins chers de Montpellier .
              </p>
            </div>
            <div className="my-7">
              <h3 className="text-primary font-semibold text-2xl text-center my-3">
                Stockez vos affaires dans un garde-meuble sécurisé en Hérault
              </h3>
              <p className="text-sm laptop:text-md leading-5 w-10/12 mx-auto text-center text-lg">
                Il est très pratique de louer un garde-meuble lors d’un
                déménagement, d’un changement de vie ou pendant une transition
                entre deux logements. Optez pour un garde-meuble sécurisé et
                entreposez vos affaires en toute tranquillité. Vous aurez la
                possibilité d’accéder à votre garde-meuble 7 jours sur 7, en
                illimité.
              </p>
            </div>
            <div className="text-center mt-12 mb-5">
              <a
                className="bg-primary font-semibold shadow hover:shadow-xl rounded-2xl text-white px-8 py-3"
                href="#formulaire"
              >
                Obtenir le tarif
              </a>
            </div>
          </div>
        </div>

        <div id="criteres" className="w-full mt-16">
          <div className="text-center">
            <h2 className="text-3xl text-primary font-semibold">
              Comment sont sélectionnés les garde-meubles ?
            </h2>
            <p className="w-8/12 mt-2 mx-auto text-gray-800">
              Nous vous proposons une sélection des{" "}
              <span className="text-black font-bold">
                {" "}
                meilleurs garde-meubles de Montpellier
              </span>
              . Tous les garde-meubles que nous vous proposons sont choisis avec
              soin selon des{" "}
              <span className="text-black font-bold">critères de qualité.</span>
            </p>
          </div>
          <div className="mt-10">
            <Carrousel />
          </div>
        </div>

        <div
          className="mt-20 flex"
          style={{ backgroundColor: "rgb(238, 238, 238)" }}
        >
          <div className="w-full px-5 md:px-20 pb-10 mt-10 tracking-wider">
            <h3 className="text-2xl font-bold">
              Nos garde-meubles de <br />
              <span className="text-primary">Montpellier</span>{" "}
            </h3>
            <div className="mt-10">
              <div className="flex my-5 w-full">
                <div className="hidden md:block w-1/12 p-1">
                  <img
                    src={check}
                    width={50}
                    height={30}
                    alt="checkbox"
                    loading="lazy"
                  />
                </div>
                <p className="w-11/12 mx-5 text-xl">
                  Plus de 5 000{" "}
                  <span className="font-bold">
                    garde-meubles <br /> comparés à Montpellier{" "}
                  </span>{" "}
                  et alentours
                </p>
              </div>
              <div className="flex my-5 w-full">
                <div className="hidden md:block w-1/12 p-1">
                  <img
                    src={check}
                    width={50}
                    height={30}
                    alt="checkbox"
                    loading="lazy"
                  />
                </div>
                <p className="w-11/12 mx-5 text-xl">
                  Sélection des{" "}
                  <span className="font-bold">
                    meilleurs garde-meubles <br />
                  </span>{" "}
                  en centre ville et en périphérie
                </p>
              </div>
              <div className="flex my-5 w-full">
                <div className="hidden md:block w-1/12 p-1">
                  <img
                    src={check}
                    width={50}
                    height={30}
                    alt="checkbox"
                    loading="lazy"
                  />
                </div>
                <p className="w-11/12 mx-5 text-xl">
                  Les{" "}
                  <span className="font-bold">
                    tarifs les plus bas garantis <br />
                  </span>{" "}
                  dans toute la région de Montpellier
                </p>
              </div>
            </div>
          </div>
          <div
            className="w-1/3 h-auto bg-center bg-cover"
            style={{
              backgroundImage: `url("${mtp1}")`,
            }}
          />
        </div>
      </div>

      <div className="my-16 w-full text-center">
        <a
          className="bg-primary text-white tracking-wider px-10 rounded-full text-lg md:text-2xl mx-auto py-3 shadow-md hover:shadow-lg"
          href="#formulaire"
        >
          RÉSERVER MON GARDE-MEUBLE
        </a>
      </div>

      <div>
        <img src={mtp2} alt="vue montpellier" loading="lazy" />
        <div className="mt-16 tracking-wider text-lg w-11/12 md:w-2/3 mx-auto">
          <h3 className="text-2xl mb-5 font-bold text-center">Montpellier</h3>
          <p className="my-4 text-justify">
            La ville de <span className="font-bold"> Montpellier</span> est
            située dans le département de{" "}
            <span className="font-bold"> l’Hérault </span>. Elle comporte{" "}
            <span className="font-bold">290 000 habitants</span>, ce qui fait
            d’elle la 7ème commune de France.
          </p>
          <p className="my-4 text-justify">
            Sa situation privilégiée fait de{" "}
            <span className="font-bold">Montpellier</span> une ville{" "}
            <span className="font-bold">très agréable à vivre</span>, qui attire
            un grand nombre d’étudiants.
          </p>
          <p className="my-4 text-justify">
            Montpellier se trouve dans la région{" "}
            <span className="font-bold">Occitanie</span>, à seulement 10
            kilomètres de la mer Méditerranée et au sud des Cévennes.
          </p>
          <p className="my-4 text-justify">
            La capitale héraultaise est la{" "}
            <span className="font-bold">
              3ème ville la plus attractive de France
            </span>
            , d’après l’institut d’études Great Place to Work.
          </p>
          <p className="my-4 text-justify">
            Cette métropole du sud de la France est reconnue pour son
            <span className="font-bold"> dynamisme </span> à la fois
            <span className="font-bold"> économique </span> et{" "}
            <span className="font-bold"> culturel </span>. Les habitants de
            Montpellier profitent d’un très{" "}
            <span className="font-bold">beau patrimoine architectural </span> et
            d’un <span className="font-bold"> climat doux et tempéré</span>.
          </p>
          <p className="my-4 text-justify">
            Pour toutes ces raisons, il s’agit de la{" "}
            <span className="font-bold">
              {" "}
              métropole française qui dispose de la plus importante croissance
              démographique depuis vingt ans{" "}
            </span>
            . Elle attire de nombreuses start-ups et multinationales telles que
            Dell.
          </p>
          <p className="my-4 text-justify">
            Ainsi, on observe une forte demande de{" "}
            <span className="font-bold"> garde-meuble à Montpellier </span>pour
            permettre aux professionnels, étudiants, jeunes actifs et retraités
            de s’installer dans la région.
          </p>
        </div>
      </div>

      <ScrollTop />
    </Layout>
  );
};

export default App;
